<template>
  <div>
    <Navbar title="Subscriptions" :hideSearch="false" />
    <div class="app-body my-5 py-5">
      <div class="columns">
        <div class="column">
          <p class="text-subtitle bold">Subscription Plans</p>
        </div>
        <div v-if="!(currentUser && currentUser.id)" class="column is-narrow">
          <router-link
            :to="{
              name: 'Register'
            }"
          >
            <b-button icon-left="account" type="is-info">
              Create Account
            </b-button>
          </router-link>
        </div>
      </div>
      <div
        v-if="!(currentUser && currentUser.id)"
        class="columns is-centered py-5"
      >
        <div class="column is-4">
          <router-link
            :to="{
              name: 'Register'
            }"
          >
            <div class="box box-anim">
              <div class="columns is-vcentered px-5 container-free">
                <div class="column">
                  <p class="mt-2 text-subtitle heading bold mb-0">Free</p>
                </div>
                <div class="column is-narrow">
                  <i class="icon-plan mdi mdi-star"></i>
                </div>
              </div>
              <div class="columns px-3">
                <div class="column px-5">
                  <p class="subtitle mt-4 bold">E-Books</p>

                  <p class="subtitle mt-4 bold">Research Articles</p>
                  <p class="subtitle mt-4 bold">Newspaper Articles</p>
                  <p class="subtitle mt-4 pb-3 bold">Journal Articles</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column is-4">
          <router-link
            :to="{
              name: 'Register'
            }"
          >
            <div class="box box-anim">
              <div class="columns is-vcentered px-5 container-researcher">
                <div class="column">
                  <p class="mt-2 text-subtitle heading bold mb-0">Researcher</p>
                  <p class="bold">5,000 PKR/year</p>
                  <span> or 500 PKR/month</span>
                </div>
                <div class="column is-narrow">
                  <i class="icon-plan mdi mdi-school"></i>
                </div>
              </div>
              <div class="columns px-3">
                <div class="column px-5">
                  <p class="subtitle mt-4 bold">Everything in Free</p>
                  <p class="subtitle mt-4 bold">Premium Content</p>
                  <p class="subtitle mt-4 bold">Digital Collection</p>
                  <p class="subtitle mt-4 bold">
                    Exclusive Access to Paid Content
                  </p>
                  <p class="subtitle mt-4 bold">Exclusive Research Articles</p>
                  <p class="subtitle mt-4 pb-3 bold">Powerful Search Tools</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div v-if="false" class="column is-4">
          <div class="box box-anim">
            <div class="columns is-vcentered px-5 container-pro">
              <div class="column">
                <p class="mt-2 text-subtitle heading bold mb-0">
                  Researcher Pro
                </p>
                <span class="bold">1500 PKR/month</span>
              </div>
              <div class="column is-narrow">
                <i class="icon-plan pro-icon mdi mdi-crown"></i>
              </div>
            </div>
            <div class="columns px-3">
              <div class="column px-5">
                <p class="subtitle mt-4 bold">Everything in Researcher</p>
                <p class="subtitle mt-4 bold">Feature 2</p>
                <p class="subtitle mt-4 bold">Feature 3</p>
                <p class="subtitle mt-4 bold">Feature 4</p>
                <p class="subtitle mt-4 pb-3 bold">Feature 5</p>
                <b-button
                  type="is-info"
                  class="bg-pro"
                  icon-left="account"
                  expanded
                >
                  Get Started
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="columns is-centered py-5">
        <div class="column is-4">
          <div class="box box-anim">
            <div class="columns is-vcentered px-5 container-free">
              <div class="column">
                <p class="mt-2 text-subtitle heading bold mb-0">Free</p>
              </div>
              <div class="column is-narrow">
                <i class="icon-plan mdi mdi-star"></i>
              </div>
            </div>
            <div class="columns px-3">
              <div class="column px-5">
                <p class="subtitle mt-4 bold">E-Books</p>

                <p class="subtitle mt-4 bold">Research Articles</p>
                <p class="subtitle mt-4 bold">Newspaper Articles</p>
                <p class="subtitle mt-4 pb-3 bold">Journal Articles</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="box box-anim">
            <div class="columns is-vcentered px-5 container-researcher">
              <div class="column">
                <p class="mt-2 text-subtitle heading bold mb-0">Researcher</p>
                <p class="bold">5,000 PKR/year</p>
                <span> or 500 PKR/month</span>
              </div>
              <div class="column is-narrow">
                <i class="icon-plan mdi mdi-school"></i>
              </div>
            </div>
            <div class="columns px-3">
              <div class="column px-5">
                <p class="subtitle mt-4 bold">Everything in Free</p>
                <p class="subtitle mt-4 bold">Premium Content</p>
                <p class="subtitle mt-4 bold">Digital Collection</p>
                <p class="subtitle mt-4 bold">
                  Exclusive Access to Paid Content
                </p>
                <p class="subtitle mt-4 bold">Exclusive Research Articles</p>
                <p class="subtitle mt-4 pb-3 bold">Powerful Search Tools</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="false" class="column is-4">
          <div class="box box-anim">
            <div class="columns is-vcentered px-5 container-pro">
              <div class="column">
                <p class="mt-2 text-subtitle heading bold mb-0">
                  Researcher Pro
                </p>
                <span class="bold">1500 PKR/month</span>
              </div>
              <div class="column is-narrow">
                <i class="icon-plan pro-icon mdi mdi-crown"></i>
              </div>
            </div>
            <div class="columns px-3">
              <div class="column px-5">
                <p class="subtitle mt-4 bold">Everything in Researcher</p>
                <p class="subtitle mt-4 bold">Feature 2</p>
                <p class="subtitle mt-4 bold">Feature 3</p>
                <p class="subtitle mt-4 bold">Feature 4</p>
                <p class="subtitle mt-4 pb-3 bold">Feature 5</p>
                <b-button
                  type="is-info"
                  class="bg-pro"
                  icon-left="account"
                  expanded
                >
                  Get Started
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p>
            Readers Lounge provides access to a rich, diverse and sustainable
            sources of knowledge and it is meant to inspire and engage in
            intellectualism.
          </p>
          <br />
          <p>
            We offer two subscription plans "Free and Researcher" for all type
            of users. Each one can choose subscription plan according to their
            reading style and research needs.
          </p>
          <br />
          <p>
            In order to purchase a subscription, deposit the amount (either
            yearly or monthly) to our Bank Account below:
          </p>
          <br />
          <b-message class="bold" type="is-info">
            IPRI Publications Account <br />
            00581650500290 <br />
            Askari Bank
          </b-message>
          <p>
            And email us the proof of payment (Bank challan, Transfer Reciept)
            along with the email address you registered with at:
            <a href="mailto:readerslounge@ipripak.org"
              >readerslounge@ipripak.org</a
            >. <br />Your account will be upgraded in 24 hours.
          </p>
          <br />
          <p v-if="false">
            We are accessible at
            <a href="mailto:readerslounge@ipripak.org"
              >readerslounge@ipripak.org</a
            >
            for any scholarly material. To help us, specify the exact details of
            the publication. We will respond to it, accordingly.
          </p>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";

export default {
  name: "Subscriptions",
  components: { Navbar, Footer },
  data() {
    return {
      isLoading: false,
      category: "image",
      sort: "title-asc",
      sortName: "Title (A-Z)",
      showSideMenu: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        perPage: 5
      }
    };
  },
  computed: {
    currentUser() {
      if (this.$store.state.currentUser) return this.$store.state.currentUser;
      else return false;
    },
    isPortrait() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    }
  },
  async created() {
    if (this.$store.state.currentUser.id) {
      await this.$store.dispatch("getUserDetail");
    }
  },
  methods: {}
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.box-focus {
  transform: scale(1.05);
}

.box-focus-more {
  transform: scale(1.05);
}

.box {
  border-radius: 15px;
  padding-bottom: 45px;
}

.box-anim:hover {
  transition: transform 125ms;
  transform: scale(1.05);
  -webkit-box-shadow: 0 0.5em 1em -0.125em #808080;
  box-shadow: 0 0.5em 1em -0.125em #808080;
}

.icon-plan {
  font-size: 80px;
  color: $white;
}

.container-free {
  background: linear-gradient(45deg, #0084ff, #0d6dc7);
  color: $white;
  margin: 3px;
  border-radius: 15px;
}

.bg-free {
  background: linear-gradient(45deg, #0084ff, #0d6dc7);
}

.container-researcher {
  background: linear-gradient(45deg, #3d4e81, #36436c);
  color: $white;
  margin: 3px;
  border-radius: 15px;
}

.bg-researcher {
  background: linear-gradient(45deg, #3d4e81, #36436c);
}

.container-pro {
  background: linear-gradient(45deg, #474747, #1b1b1b);
  color: $white;
  margin: 3px;
  border-radius: 15px;
}

.pro-icon {
  color: #ffdf00 !important;
}

.bg-pro {
  background: linear-gradient(45deg, #474747, #1b1b1b);
}
</style>
