var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-footer"},[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-hidden-mobile"}),_c('div',{staticClass:"column is-narrow has-text-centered"},[_c('a',{attrs:{"href":"https://www.facebook.com/ipri.pk/","target":"_blank"}},[_c('b-icon',{attrs:{"icon":"facebook","type":"is-info","size":"is-medium"}})],1)]),_c('div',{staticClass:"column is-narrow has-text-centered"},[_c('a',{attrs:{"href":"https://www.youtube.com/c/IslamabadPolicyResearchInstitute","target":"_blank"}},[_c('b-icon',{attrs:{"icon":"youtube","type":"is-danger","size":"is-medium"}})],1)]),_c('div',{staticClass:"column is-narrow has-text-centered"},[_c('a',{attrs:{"href":"https://twitter.com/IPRI_Pak","target":"_blank"}},[_c('b-icon',{attrs:{"icon":"twitter","type":"is-info is-light","size":"is-medium"}})],1)]),_c('div',{staticClass:"column is-narrow has-text-centered"},[_c('a',{attrs:{"href":"https://www.linkedin.com/company/islamabad-policy-research-institute","target":"_blank"}},[_c('b-icon',{attrs:{"icon":"linkedin","type":"is-info","size":"is-medium"}})],1)]),_c('div',{staticClass:"column is-narrow has-text-centered"},[_c('a',{attrs:{"href":"https://www.instagram.com/ipripak1/","target":"_blank"}},[_c('b-icon',{attrs:{"icon":"instagram","type":"is-danger","size":"is-medium"}})],1)]),_c('div',{staticClass:"column is-hidden-mobile"})]),_c('div',{staticClass:"columns is-vcentered mt-5"},[_c('div',{staticClass:"column has-text-centered"},[_c('router-link',{attrs:{"to":{
          name: 'Explore',
          query: {
            category: 'eBook'
          }
        }}},[_c('a',{staticClass:"has-text-dark"},[_vm._v(" Library Catalogue ")])])],1),_c('div',{staticClass:"column has-text-centered"},[_c('router-link',{attrs:{"to":{
          name: 'Explore',
          query: {
            category: 'image'
          }
        }}},[_c('a',{staticClass:"has-text-dark"},[_vm._v(" Digital Collections ")])])],1),_c('div',{staticClass:"column has-text-centered"},[_c('router-link',{attrs:{"to":{
          name: 'Researchers'
        }}},[_c('a',{staticClass:"has-text-dark"},[_vm._v(" Researchers ")])])],1),_vm._m(0),_c('div',{staticClass:"column has-text-centered"},[_c('router-link',{attrs:{"to":{
          name: 'About'
        }}},[_c('a',{staticClass:"has-text-dark"},[_vm._v(" About ")])])],1),_c('div',{staticClass:"column has-text-centered"},[_c('router-link',{attrs:{"to":{
          name: 'About'
        }}},[_c('a',{staticClass:"has-text-dark"},[_vm._v(" Contact ")])])],1)]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column has-text-centered"},[_c('a',{staticClass:"has-text-dark",attrs:{"href":"https://ipripak.org/","target":"_blank"}},[_vm._v(" IPRI ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns mt-5"},[_c('div',{staticClass:"column"}),_c('div',{staticClass:"column mx-5 is-narrow has-text-centered"},[_c('p',{staticClass:"text-small"},[_vm._v(" © Readers Lounge. All rights reserved - IPRI ")])]),_c('div',{staticClass:"column"})])
}]

export { render, staticRenderFns }