<template>
  <div class="app-footer">
    <div class="columns is-mobile">
      <div class="column is-hidden-mobile"></div>
      <div class="column is-narrow has-text-centered">
        <a href="https://www.facebook.com/ipri.pk/" target="_blank">
          <b-icon icon="facebook" type="is-info" size="is-medium" />
        </a>
      </div>
      <div class="column is-narrow has-text-centered">
        <a
          href="https://www.youtube.com/c/IslamabadPolicyResearchInstitute"
          target="_blank"
        >
          <b-icon icon="youtube" type="is-danger" size="is-medium" />
        </a>
      </div>
      <div class="column is-narrow has-text-centered">
        <a href="https://twitter.com/IPRI_Pak" target="_blank">
          <b-icon icon="twitter" type="is-info is-light" size="is-medium" />
        </a>
      </div>
      <div class="column is-narrow has-text-centered">
        <a
          href="https://www.linkedin.com/company/islamabad-policy-research-institute"
          target="_blank"
        >
          <b-icon icon="linkedin" type="is-info" size="is-medium" />
        </a>
      </div>
      <div class="column is-narrow has-text-centered">
        <a href="https://www.instagram.com/ipripak1/" target="_blank">
          <b-icon icon="instagram" type="is-danger" size="is-medium" />
        </a>
      </div>
      <div class="column is-hidden-mobile"></div>
    </div>
    <div class="columns is-vcentered mt-5">
      <div class="column has-text-centered">
        <router-link
          :to="{
            name: 'Explore',
            query: {
              category: 'eBook'
            }
          }"
        >
          <a class="has-text-dark ">
            Library Catalogue
          </a>
        </router-link>
      </div>
      <div class="column has-text-centered">
        <router-link
          :to="{
            name: 'Explore',
            query: {
              category: 'image'
            }
          }"
        >
          <a class="has-text-dark ">
            Digital Collections
          </a>
        </router-link>
      </div>
      <div class="column has-text-centered">
        <router-link
          :to="{
            name: 'Researchers'
          }"
        >
          <a class="has-text-dark ">
            Researchers
          </a>
        </router-link>
      </div>
      <div class="column has-text-centered">
        <a href="https://ipripak.org/" target="_blank" class="has-text-dark ">
          IPRI
        </a>
      </div>
      <div class="column has-text-centered">
        <router-link
          :to="{
            name: 'About'
          }"
        >
          <a class="has-text-dark">
            About
          </a>
        </router-link>
      </div>
      <div class="column has-text-centered">
        <router-link
          :to="{
            name: 'About'
          }"
        >
          <a class="has-text-dark">
            Contact
          </a>
        </router-link>
      </div>
    </div>
    <div class="columns mt-5">
      <div class="column"></div>
      <div class="column mx-5 is-narrow has-text-centered">
        <p class="text-small">
          © Readers Lounge. All rights reserved - IPRI
        </p>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.app-footer {
  background-color: #f8f8f8;
  border-top: 2px solid $gray100;
  padding: 3em 10vw;
}
</style>
