<template>
  <div>
    <div
      v-if="showNotice && notice[0].enabled"
      class="columns notice is-mobile px-5"
      :style="`background-color:${notice[0].backgroundColour}`"
    >
      <div :class="!isPortrait ? 'column has-text-centered' : 'column'">
        <p class="mt-2 bold" :style="`color:${notice[0].foregroundColour}`">
          <i :class="`mdi mdi-${notice[0].icon}`"></i>
          {{ notice[0].message }}
        </p>
      </div>
      <div class="column is-narrow mt-2 px-0">
        <a
          @click="
            () => {
              this.showNotice = false;
            }
          "
          class="has-text-white"
        >
          <i class="mdi mdi-close-circle"></i>
        </a>
      </div>
    </div>
    <div v-if="isPortrait" class="columns navbar-container m-0">
      <div class="column has-text-centered">
        <router-link
          :to="{
            name: 'Home'
          }"
        >
          <img src="@/assets/logo.png" style="margin-top:12px;" />
        </router-link>
      </div>
    </div>
    <div class="columns navbar is-vcentered mb-0 mx-0 is-mobile home-body">
      <div v-if="!isPortrait" class="column is-narrow m-2">
        <router-link
          :to="{
            name: 'Home'
          }"
        >
          <img src="@/assets/logo.png" style="height:64px;margin-top:12px;" />
        </router-link>
      </div>
      <div class="column">
        <p
          v-if="title && !isPortrait"
          :class="!isPortrait ? 'text-heading bold ml-5' : 'text-heading bold'"
        >
          {{ title }}
        </p>
      </div>
      <div class="column is-narrow has-text-right m-2">
        <span v-if="currentUser && currentUser.name">
          <b-dropdown position="is-bottom-left" aria-role="list">
            <template #trigger>
              <a class="has-text-dark">
                <div class="columns is-vcentered is-mobile">
                  <div class="column px-1 is-narrow">
                    <div
                      class="profilePictureNavbar"
                      :style="profilePictureStyleClass"
                    ></div>
                  </div>
                  <div class="column px-1 is-narrow">
                    {{ currentUser.name }}
                  </div>
                  <div class="column px-0 is-narrow">
                    <b-tag
                      v-if="currentUser.role == 'ipri'"
                      type="is-info"
                      class="ml-2"
                      rounded
                    >
                      IPRI Staff
                    </b-tag>
                    <b-tag v-else type="is-info" class="ml-2" rounded>
                      {{ currentUser.role | capitalize }} User
                    </b-tag>
                  </div>
                </div>
              </a>
            </template>
            <router-link
              v-if="currentUser.role == 'ipri'"
              :to="{
                name: 'MyIssuances'
              }"
              class="text-black"
            >
              <b-dropdown-item aria-role="listitem">
                My Issuances
              </b-dropdown-item>
            </router-link>

            <router-link
              v-if="currentUser.role == 'admin'"
              :to="{
                name: 'Dashboard'
              }"
              class="text-black"
            >
              <b-dropdown-item aria-role="listitem">
                Admin Panel
              </b-dropdown-item>
            </router-link>

            

            <router-link
              :to="{
                name: 'ChangeProfilePicture'
              }"
              class="text-black"
            >
              <b-dropdown-item aria-role="listitem">
                Update Profile Picture
              </b-dropdown-item>
            </router-link>

            <router-link
              :to="{
                name: 'ChangePassword'
              }"
              class="text-black"
            >
              <b-dropdown-item aria-role="listitem">
                Change Password
              </b-dropdown-item>
            </router-link>

            <router-link
              :to="{
                name: 'Login'
              }"
              class="text-black"
            >
              <b-dropdown-item
                @click.native="store.dispatch('logout')"
                aria-role="listitem"
              >
                Sign Out
              </b-dropdown-item>
            </router-link>
          </b-dropdown>
        </span>
        <span v-else>
          <router-link :to="{ name: 'Register' }">
            <a class="has-text-dark">
              <i class="mdi mdi-account-plus pr-1"></i>Create Account
            </a>
          </router-link>
          <span class="mx-2">|</span>
          <router-link :to="{ name: 'Login' }">
            <a class="has-text-dark">
              <i class="mdi mdi-account pr-1"></i>Account Login
            </a>
          </router-link>
        </span>
        <div
          :class="
            !isPortrait ? 'columns is-vcentered mt-5' : 'columns is-vcentered'
          "
        >
          <div class="column"></div>
          <div class="column is-narrow">
            <b-dropdown position="is-bottom-left" aria-role="list">
              <template #trigger>
                <b-icon icon="menu" size="is-medium"></b-icon>
              </template>
              <router-link
                :to="{
                  name: 'Home'
                }"
              >
                <b-dropdown-item aria-role="listitem">
                  Home
                </b-dropdown-item>
              </router-link>

              <router-link
                :to="{
                  name: 'Explore',
                  query: {
                    category: 'eBook'
                  }
                }"
              >
                <b-dropdown-item aria-role="listitem">
                  Library Catalogue
                </b-dropdown-item>
              </router-link>

              <router-link
                :to="{
                  name: 'Explore',
                  query: {
                    category: 'image'
                  }
                }"
              >
                <b-dropdown-item aria-role="listitem">
                  Digital Collections
                </b-dropdown-item></router-link
              >

              <router-link
                :to="{
                  name: 'Researchers'
                }"
              >
                <b-dropdown-item aria-role="listitem">
                  Researchers
                </b-dropdown-item>
              </router-link>
              <router-link
                :to="{
                  name: 'About'
                }"
              >
                <b-dropdown-item aria-role="listitem">About</b-dropdown-item>
              </router-link>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!hideSearch" class="columns navbar is-vcentered m-0 search-box ">
      <div class="column is-2 p-0">
        <b-select v-model="category" expanded placeholder="Search type">
          <option value="all">All Resources</option>
          <option value="PrintedBook">Books</option>
          <option value="eBook">E-Books</option>
          <option value="Research">Research Articles</option>
          <option value="eResearch">E-Research Articles</option>
          <option value="Journal">Journals</option>
          <option value="eJournal">E-Journals</option>
          <option value="Newspaper">Newspapers</option>
          <option value="eNewspaper">E-Newspapers</option>
          <option value="image">Images</option>
          <option value="video">Videos</option>
          <option value="audio">Audios</option>
          <option value="document">Documents</option>
          <option value="dataset">Datasets</option>
          <option value="map">Maps</option>
        </b-select>
      </div>
      <div class="column is-2 p-0">
        <b-select v-model="searchBy" expanded placeholder="Search By">
          <option value="title">Title</option>
          <option value="authors">Authors</option>
          <option value="keywords">Keywords</option>
        </b-select>
      </div>
      <div class="column p-0">
        <b-autocomplete
          v-model="searchQuery"
          :data="filteredSearchSuggestions"
          placeholder="Search..."
          @select="option => (searchQuery = option)"
          @keyup.native.enter="search()"
          expanded
        >
          <template #empty>
            <span v-if="searchQuery.length > 2">
              No results found
            </span>
          </template>
        </b-autocomplete>
      </div>
      <div class="column is-narrow p-0">
        <p v-if="!isPortrait" class="control" @click="search()">
          <b-button type="is-primary">
            <i class="mdi mdi-magnify"></i>
          </b-button>
        </p>
        <b-button
          v-if="isPortrait"
          type="is-primary"
          expanded
          @click="search()"
        >
          <i class="mdi mdi-magnify"></i> Search
        </b-button>
      </div>
    </div>
    <div class="scrollToTopOverlay" @click="scrollToTop()">
      <i class="mdi mdi-chevron-up"></i>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Navbar",
  props: {
    title: String,
    hideSearch: Boolean
  },
  data() {
    return {
      store,
      showNotice: false,
      filterResource: undefined,
      searchBy: "title",
      searchQuery: "",
      category: "all"
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    profilePictureStyleClass() {
      return this.$store.state.userDetail
        ? "background-image: url(" +
            this.$store.state.userDetail.profilePicture +
            ")"
        : "";
    },
    notice() {
      return this.$store.state.noticeObject;
    },
    autoComplete() {
      return this.$store.state.autoComplete;
    },
    filteredSearchSuggestions() {
      if (!this.autoComplete || !this.searchQuery) return [];
      if (this.searchQuery.length < 2) return [];
      if (this.searchBy == "authors") {
        return this.autoComplete.authors.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) >= 0
          );
        });
      }
      if (this.searchBy == "title") {
        return this.autoComplete.titles.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) >= 0
          );
        });
      }
      if (this.searchBy == "keywords") {
        return this.autoComplete.keywords.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) >= 0
          );
        });
      }
      return [];
    },
    isPortrait() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    notice: function(value) {
      if (value && value[0]) {
        this.showNotice = true;
      } else {
        this.showNotice = false;
      }
    }
  },
  async created() {
    if (this.$store.state.currentUser.id) {
      await this.$store.dispatch("getUserDetail");
    }
    await this.$store.dispatch("getNoticeObject");
  },
  methods: {
    comingSoon() {
      alert("Feature coming soon");
    },
    search() {
      this.$router.push({
        name: "Search",
        query: {
          searchBy: this.searchBy,
          searchQuery: this.searchQuery,
          category: this.category
        }
      });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.navbar {
  background-color: $white;
  border-bottom: 2px solid $gray100;
  padding: 1em 10vw;
}
.navbar-container {
  background-color: $white;
  padding: 1em 10vw;
}
.search-box {
  background-color: #f8f8f8;
  z-index: auto;
}

.notice {
  background-color: $primary-color;
  color: $white;
}

.scrollToTopOverlay {
  position: fixed;
  bottom: 5vh;
  right: 5vh;
  color: white;
  background-color: $primary-color;
  padding: 10px 16px;
  font-size: 24px;
  border-radius: 125%;
  cursor: pointer;
  z-index: 100;
}

.profilePictureNavbar {
  border: 2px solid $primary-color;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center !important;
  background-origin: border-box;
}
</style>
